export enum RPC {
  HANDLE_MAIN_SEQUENCE_ENDED
}

export type State = {
  rpc: RPC | null;
  narration: string;
};

export type StateChangeHandler = (stateChange: State) => void;

export const defaultState: State = { narration: '', rpc: null };
