import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
  Cv,
  cvBrand,
  Github,
  githubBrand,
  LinkedIn,
  linkedInBrand,
  Npm,
  npmBrand
} from './Icons';
import { BLACK } from '../lib';

const Container = styled.div`
  font-family: 'Special Elite', 'Monoton', sans-serif;

  @media (orientation: portrait) {
    padding: 58px 24px 0 24px;
    text-align: center;
  }

  @media (orientation: landscape) {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    margin-right: 50%;
    overflow: auto;
    padding: 52px 48px 52px 24px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;

  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
`;

const Items = styled.ul`
  @media (orientation: landscape) {
    margin-top: 48px;

    @media (max-width: 900px) {
      margin-top: 24px;
    }
  }

  @media (orientation: portrait) {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
`;

const Item = styled.li`
  margin-bottom: 24px;
`;

const Icon = styled.div`
  display: inline-block;

  @media (orientation: landscape) {
    height: 24px;
    line-height: 24px;
    margin-right: 12px;
    width: 24px;
  }

  @media (orientation: portrait) {
    height: 32px;
    line-height: 32px;
    margin: 0 12px;
    width: 32px;
  }
`;

const Indicator = styled.div``;

const LinkStyle = styled.a<{ brand: string }>`
  align-items: center;
  color: ${BLACK};
  display: flex;
  font-size: 2rem;
  position: relative;
  text-decoration: none;

  &:active {
    color: ${BLACK};
  }

  @media (orientation: landscape) {
    ${Indicator} {
      background-color: ${(p) => p.brand};
      height: 0;
      margin-left: -8px;
      margin-right: 5px;
      transition: height 0.5s;
      width: 3px;
    }

    &:hover {
      ${Indicator} {
        height: 24px;
      }
    }
  }

  @media (orientation: portrait) {
    position: relative;

    ${Indicator} {
      background-color: ${(p) => p.brand};
      bottom: -6px;
      height: 3px;
      left: 16px;
      position: absolute;
      transition: width 0.5s;
      width: 0;
    }

    &:hover {
      ${Indicator} {
        width: 24px;
      }
    }
  }

  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

const LinkName = styled.div`
  margin-bottom: -8px;

  @media (orientation: portrait) {
    display: none;
  }
`;

const Link = ({
  icon,
  name,
  href,
  brand
}: {
  icon: ReactNode;
  name: string;
  href: string;
  brand: string;
}) => (
  <LinkStyle brand={brand} href={href}>
    <Indicator />
    <Icon>{icon}</Icon> <LinkName>{name}</LinkName>
  </LinkStyle>
);

export const Menu = () => (
  <Container>
    <Title>Andrew McKenzie</Title>
    <Description>Web Engineer &middot; Boise, ID</Description>
    <Items>
      <Item>
        <Link
          icon={<Github />}
          name="Github"
          href="https://github.com/andrewpmckenzie"
          brand={githubBrand}
        />
      </Item>
      <Item>
        <Link
          icon={<LinkedIn />}
          name="Linked in"
          href="https://www.linkedin.com/in/andrewpmckenzie"
          brand={linkedInBrand}
        />
      </Item>
      <Item>
        <Link
          icon={<Npm />}
          name="NPM"
          href="https://www.npmjs.com/~andrewpmckenzie"
          brand={npmBrand}
        />
      </Item>
      <Item>
        <Link icon={<Cv />} name="CV" href="/#/cv" brand={cvBrand} />
      </Item>
    </Items>
  </Container>
);
