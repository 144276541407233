import {
  Context,
  ContextProperties,
  NodeProperties,
  Transition
} from '../model';
import { debug } from 'debug';

const log = debug('viz:sequence:reset');

export const reset = (
  contextProperties: Partial<ContextProperties> = {},
  nodeProperties: Partial<NodeProperties> = {}
): Transition =>
  function*(context: Context) {
    log('advance()');
    context.reset(contextProperties, nodeProperties);
    yield;
  };
