import { Context, Transition } from '../model';
import { debug } from 'debug';

const log = debug('viz:sequence2d:delay');

export const delay = (delayMs: number): Transition =>
  function*(context: Context) {
    log(`Delaying for ${delayMs}ms`);
    yield new Promise((r) => setTimeout(() => r(), delayMs));
  };
