import { AbstractPattern } from './AbstractPattern';
import { Pattern } from './Pattern';

export type ShutterPatternOptions<T> = { sequence1d: T[] };
export class ShutterPattern<T> extends AbstractPattern<T>
  implements Pattern<T> {
  constructor(
    numCanvasCols: number,
    numCanvasRows: number,
    private opts: ShutterPatternOptions<T>
  ) {
    super(numCanvasCols, numCanvasRows);
    this.primeCache();
  }

  forCoordinates(canvasCol: number, canvasRow: number): T {
    const position = Math.min(
      canvasCol,
      canvasRow,
      this.numCanvasCols - 1 - canvasCol,
      this.numCanvasRows - 1 - canvasRow
    );

    return this.opts.sequence1d[position % this.opts.sequence1d.length];
  }
}
