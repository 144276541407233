export type Experience = {
  name: string;
  experience: 'h' | 'm' | 'l';
};

export type ExperienceGroup = {
  name: string;
  items: Experience[];
};

export type EmploymentDetails = {
  employer: string;
  from: string;
  to: string;
  role: string;
  description?: string;
};

export type EducationDetails = {
  institution: string;
  location: string;
  degree: string;
  from: string;
  to: string;
};

export type CvData = {
  name: string;
  occupation: string;
  phone: string;
  email: string;
  location: string;
  experienceGroups: ExperienceGroup[];
  employment: EmploymentDetails[];
  education: EducationDetails[];
};

export const data: CvData = {
  name: 'Andrew McKenzie',
  occupation: 'Software Engineer (web)',
  phone: '(917) 865-2311',
  email: 'andrew@mcke.nz',
  location: 'Boise, ID',
  experienceGroups: [
    {
      name: 'Frontend',
      items: [
        { name: 'HTML5', experience: 'h' },
        { name: 'CSS3', experience: 'h' },
        { name: 'JavaScript / ES6', experience: 'h' },
        { name: 'TypeScript', experience: 'h' },
        { name: 'CoffeeScript', experience: 'm' },
        { name: 'A11y / ARIA', experience: 'h' },
        { name: 'I18n', experience: 'm' },
        { name: 'SEO', experience: 'h' },
        { name: 'Automated testing', experience: 'h' },
        { name: 'React', experience: 'h' },
        { name: 'React Hooks', experience: 'h' },
        { name: 'Angular', experience: 'h' },
        { name: 'AngularJS', experience: 'm' },
        { name: 'NgRx', experience: 'm' },
        { name: 'RxJS', experience: 'm' },
        { name: 'D3', experience: 'm' },
        { name: 'Vue', experience: 'l' },
        { name: 'Styled Components', experience: 'h' },
        { name: 'Meteor', experience: 'm' },
        { name: 'Webpack', experience: 'm' },
        { name: 'Redux', experience: 'm' },
        { name: 'GraphQL', experience: 'l' },
        { name: 'Jest', experience: 'h' },
        { name: 'Jasmine', experience: 'h' },
        { name: 'SASS', experience: 'h' },
        { name: 'LESS', experience: 'h' },
        { name: 'Storybook', experience: 'h' }
      ]
    },
    {
      name: 'Backend',
      items: [
        { name: 'Java', experience: 'h' },
        { name: 'Scala', experience: 'm' },
        { name: 'Python', experience: 'l' },
        { name: 'Ruby', experience: 'l' },
        { name: 'C#', experience: 'l' },
        { name: 'NodeJS', experience: 'h' },
        { name: 'SQL', experience: 'm' },
        { name: 'Guice', experience: 'l' },
        { name: 'App engine', experience: 'l' },
        { name: 'Nginx', experience: 'l' },
        { name: 'gRPC', experience: 'l' },
        { name: 'Protobuf', experience: 'm' },
        { name: 'Mongo', experience: 'm' },
        { name: 'Docker', experience: 'm' },
        { name: 'AWS', experience: 'h' },
        { name: 'GCS', experience: 'h' },
        { name: 'Serverless', experience: 'm' },
        { name: 'Jenkins', experience: 'm' },
        { name: 'RabbitMQ', experience: 'l' },
        { name: 'Travis', experience: 'm' }
      ]
    },
    {
      name: 'Organizational',
      items: [
        { name: 'Stakeholder engagement', experience: 'm' },
        { name: 'Project management', experience: 'm' },
        { name: 'Product management', experience: 'm' },
        { name: 'Tech lead', experience: 'h' },
        { name: 'Oncall / incident response', experience: 'm' },
        { name: 'Scrum', experience: 'l' },
        { name: 'Kanban', experience: 'm' },
        { name: 'Lean', experience: 'm' }
      ]
    }
  ],
  employment: [
    {
      employer: 'Spotify',
      from: '2019',
      to: 'Now',
      role: 'Sr Software Engineer / Tech Lead',
      description:
        'Creator (Artist / Label tools) team. Delivered new ' +
        'team-management and invite pages. Created passive visual regression testing ' +
        'system tailored for our CI/CD flow.'
    },
    {
      employer: 'Foursquare',
      from: '2018',
      to: '2019',
      role: 'Sr Engineering Manager',
      description:
        "Managed web team (5 reports) and product development across all Foursquare's web properties. " +
        'Coordinated delivery of new foursquare.com homepage, Jagermeister microsite, and ' +
        'improved stakeholder engagement model.'
    },
    {
      employer: 'Google',
      from: '2016',
      to: '2018',
      role: 'Sr Software Engineer',
      description:
        'Led frontend rewrite of law enforcement portal to use Material design, Angular, Typescript, ' +
        'and A11y standards. Created configuration-based dynamic webform system. Taught ' +
        'advanced classes on Typescript and Angular.'
    },
    {
      employer: 'Electronifie',
      from: '2015',
      to: '2016',
      role: 'Sr Software Engineer',
      description:
        'One of 7 engineers building a digital trading platform for corporate bonds.'
    },
    {
      employer: 'Foursquare',
      from: '2012',
      to: '2015',
      role: 'Sr Software Engineer',
      description:
        'Web and growth teams. Delivered high-traffic pages, dozens of multi-variate SEO experiments, ' +
        'introduced numerous improvements to automated testing, created microsites, created Admin page framework ' +
        'which allowed rich UIs with minimal frontend code.'
    },
    {
      employer: 'Bank of New Zealand',
      from: '2010',
      to: '2012',
      role: 'Analyst Programmer / Scrummaster',
      description:
        'Landing-site and IB teams. Created investment calculators, rolled out new site design + ' +
        'frontend unification, built prototype for product which would evolve into YouMoney.'
    },
    {
      employer: 'Urology Associates',
      from: '2007',
      to: '2010',
      role: 'Systems Administrator / IT Manager',
      description:
        'Solely responsible for building/adopting, maintaining, and supporting critical systems for ' +
        '40-employee, 5-surgeon medical practice.'
    },
    {
      employer: 'University of Canterbury',
      from: '2006',
      to: '2008',
      role: 'Tutor',
      description:
        'Tutored various computer science + IT courses. Relief lecturer for 300-level computer security paper.'
    }
  ],
  education: [
    {
      institution: 'University of Canterbury',
      location: 'New Zealand',
      degree: 'MSc, Computer Science (incomplete)',
      from: '2007',
      to: '2008'
    },

    {
      institution: 'University of Canterbury',
      location: 'New Zealand',
      degree: 'PGDipSci, Computer Security and Forensics',
      from: '2005',
      to: '2006'
    },

    {
      institution: 'University of Otago',
      location: 'New Zealand',
      degree: 'BSc, Computer Science',
      from: '2002',
      to: '2004'
    }
  ]
};
