import React from 'react';
import ReactDOM from 'react-dom';
import { Header } from './components/Header';
import { CVPage } from './CVPage';
import { MainPage } from './MainPage';
import { HashRouter, Switch, Route } from 'react-router-dom';

ReactDOM.render(
  <Header>
    <HashRouter>
      <Switch>
        <Route path="/cv" exact>
          <CVPage />
        </Route>
        <Route path="/">
          <MainPage />
        </Route>
      </Switch>
    </HashRouter>
  </Header>,
  document.getElementById('root')
);
