import * as d3 from 'd3';
import { debug } from 'debug';
import { BLACK } from '../pallette';
import { Node, NodeProperties } from './Node';
import { defaultState, State } from './State';

const log = debug('viz:Context');

export interface ContextProperties {
  backgroundColor: string;
  transitionTimeMs: number;
  easing: 'linear' | 'default';
  state: State;
}

export class Context implements ContextProperties {
  // Bangs since initial value is assigned by call to reset() from within constructor
  // (which TS doesn't like)
  public backgroundColor!: string;
  public transitionTimeMs!: number;
  public easing!: 'linear' | 'default';
  public state!: State;

  public nodes: Node[];

  public totalRows: Readonly<number>;
  public totalCols: Readonly<number>;
  public standardNodeSize: Readonly<number>;
  public gapSize: Readonly<number>;

  constructor(
    public dimensionsPx: { width: number; height: number },
    contextOpts: {
      minRows?: number;
      minCols?: number;
      maxSize?: number;
      gap?: number;
    } = {}
  ) {
    log('construct(%s)', dimensionsPx);

    if (!dimensionsPx.width || !dimensionsPx.height) {
      throw new Error('Either width or height is 0.');
    }

    const { nodes, totalRows, totalCols, size, gap } = Context.createNodes(
      dimensionsPx,
      contextOpts
    );
    this.nodes = nodes;
    this.totalRows = totalRows;
    this.totalCols = totalCols;
    this.standardNodeSize = size;
    this.gapSize = gap;
    this.reset();
  }

  getNodeAt({ rowPos, colPos }: { rowPos: number; colPos: number }) {
    return this.nodes[rowPos * this.totalCols + colPos];
  }

  reset(
    {
      backgroundColor = BLACK,
      transitionTimeMs = 0,
      easing = 'default',
      state = defaultState
    }: Partial<ContextProperties> = {},
    nodeProperties: Partial<NodeProperties> = {}
  ) {
    this.nodes.forEach((n) => n.reset(nodeProperties));
    this.backgroundColor = backgroundColor;
    this.transitionTimeMs = transitionTimeMs;
    this.easing = easing;
    this.state = state;
  }

  private static createNodes(
    dimensionsPx: { width: number; height: number },
    // TODO: make gap a %f
    { minRows = 30, minCols = 30, maxSize = 25, gap = 5 } = {}
  ) {
    const minSizeForCols = (dimensionsPx.width - minCols * gap) / minCols;
    const minSizeForRows = (dimensionsPx.height - minRows * gap) / minRows;
    const size = Math.min(minSizeForCols, minSizeForRows, maxSize);

    const maxBoundingBox = size + gap;
    let totalRows = (dimensionsPx.height / maxBoundingBox) | 0;
    let totalCols = (dimensionsPx.width / maxBoundingBox) | 0;

    const grid = d3
      .range(totalRows)
      .map((rowPos) =>
        d3
          .range(totalCols)
          .map(
            (colPos) =>
              new Node(rowPos, colPos, size, dimensionsPx, totalRows, totalCols)
          )
      );
    return { nodes: d3.merge(grid) as Node[], totalRows, totalCols, size, gap };
  }
}
