import { Context, Transition } from '../model';
import { debug } from 'debug';
import { CurriedPattern } from '../pattern';
import {
  NodePropertiesWithRelativeSize,
  transferNodePropertiesWithRelativeSize
} from '../util/transferNodeProperties';

const log = debug('viz:sequence2d:allAtOnce');

export const allAtOnce = ({
  background = null as string | null,
  pattern = null as CurriedPattern<
    Partial<NodePropertiesWithRelativeSize>
  > | null,
  transitionTimeMs = null as number | null
} = {}): Transition =>
  function*(context: Context) {
    log('advancing');

    if (transitionTimeMs != null) {
      context.transitionTimeMs = transitionTimeMs;
    }

    if (background != null) {
      context.backgroundColor = background;
    }

    if (pattern != null) {
      const props = pattern(context.totalCols, context.totalRows);
      context.nodes.forEach((node) => {
        transferNodePropertiesWithRelativeSize(
          props.forNode(node),
          node,
          context
        );
      });
    }

    yield;
  };
