import { BLUE, TRANSPARENT, WHITE } from '../../../lib';
import { createCurriedPattern } from '../../../lib/pattern';
import { menu } from '../../../lib/transition';
import { NodePropertiesWithRelativeSize } from '../../../lib/util/transferNodeProperties';

const BACKGROUND: Partial<NodePropertiesWithRelativeSize> = {
  color: TRANSPARENT,
  relativeSize: 1
};
const WORD: Partial<NodePropertiesWithRelativeSize> = {
  color: WHITE,
  relativeSize: 0.5
};
const LINKED_F: Partial<NodePropertiesWithRelativeSize> = {
  color: WHITE,
  relativeSize: 1
};
const LINKED_B: Partial<NodePropertiesWithRelativeSize> = {
  color: BLUE,
  relativeSize: 1
};
const GITHUB_F: Partial<NodePropertiesWithRelativeSize> = {
  color: WHITE,
  relativeSize: 1
};
const GITHUB_B: Partial<NodePropertiesWithRelativeSize> = {
  color: TRANSPARENT,
  relativeSize: 1
};
const CV_F: Partial<NodePropertiesWithRelativeSize> = {
  color: WHITE,
  relativeSize: 1
};
const CV_B: Partial<NodePropertiesWithRelativeSize> = {
  color: TRANSPARENT,
  relativeSize: 1
};
const OUTLINE: Partial<NodePropertiesWithRelativeSize> = {
  color: 'rgba(255, 255, 255, 0.1)',
  relativeSize: 0.5
};

type WordGeneric<T> = [T[], T[], T[], T[], T[], T[]];
type Word = WordGeneric<Partial<NodePropertiesWithRelativeSize>>;

// prettier-ignore
const basePattern = <T = Partial<NodePropertiesWithRelativeSize>>({
  _ = BACKGROUND as T,

  G = GITHUB_F as T,
  g = GITHUB_B as T,
  X = BACKGROUND as T,

  L = LINKED_F as T,
  l = LINKED_B as T,
  Y = BACKGROUND as T,

  C = CV_F as T,
  c = CV_B as T,
  Z = BACKGROUND as T,

  word = [
      [_, _, _, _, _, _, _,  _, _, _, _, _, _, _,  _, _, _, _, _, _, _],
      [_, _, _, _, _, _, _,  _, _, _, _, _, _, _,  _, _, _, _, _, _, _],
      [_, _, _, _, _, _, _,  _, _, _, _, _, _, _,  _, _, _, _, _, _, _],
      [_, _, _, _, _, _, _,  _, _, _, _, _, _, _,  _, _, _, _, _, _, _],
      [_, _, _, _, _, _, _,  _, _, _, _, _, _, _,  _, _, _, _, _, _, _],
      [_, _, _, _, _, _, _,  _, _, _, _, _, _, _,  _, _, _, _, _, _, _],
  ] as [T[], T[], T[], T[], T[], T[]],

} = {}) => [
    ...word,
    [_, _, _, _, _, _, _,  _, _, _, _, _, _, _,  _, _, _, _, _, _, _],
    [_, _, _, _, _, _, _,  _, _, _, _, _, _, _,  _, _, _, _, _, _, _],
    [X, X, X, X, X, X, X,  Y, Y, Y, Y, Y, Y, Y,  Z, Z, Z, Z, Z, Z, Z],
    [X, g, G, G, G, g, X,  Y, l, l, l, l, l, Y,  Z, C, C, c, c, c, Z],
    [X, G, g, g, g, G, X,  Y, l, L, l, l, l, Y,  Z, C, c, c, c, c, Z],
    [X, G, g, g, g, G, X,  Y, l, L, L, L, l, Y,  Z, C, C, c, c, c, Z],
    [X, G, G, g, G, G, X,  Y, l, L, L, L, l, Y,  Z, c, c, c, C, c, C],
    [X, g, G, g, G, g, X,  Y, l, l, l, l, l, Y,  Z, c, c, c, c, C, Z],
    [X, X, X, X, X, X, X,  Y, Y, Y, Y, Y, Y, Y,  Z, Z, Z, Z, Z, Z, Z],
];

const menuWord = ({ _ = BACKGROUND, W = WORD } = {}): Word => [
  [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
  [_, W, W, W, W, W, _, W, W, W, _, W, _, _, W, _, W, _, _, W, _],
  [_, W, _, W, _, W, _, W, _, _, _, W, W, _, W, _, W, _, _, W, _],
  [_, W, _, W, _, W, _, W, W, W, _, W, _, W, W, _, W, _, _, W, _],
  [_, W, _, W, _, W, _, W, _, _, _, W, _, _, W, _, W, _, _, W, _],
  [_, W, _, W, _, W, _, W, W, W, _, W, _, _, W, _, W, W, W, W, _]
];

const githubWord = ({ _ = BACKGROUND, W = WORD } = {}): Word => [
  [_, W, W, W, _, _, _, W, _, _, W, _, _, W, _, _, _, W, _, _, _],
  [W, _, _, _, _, W, _, W, _, _, W, _, _, W, _, _, _, W, _, _, _],
  [W, _, _, _, _, _, W, W, W, _, W, W, W, W, W, _, W, W, W, W, _],
  [W, _, W, W, _, W, _, W, _, _, W, _, _, W, W, _, W, W, _, _, W],
  [W, _, _, W, _, W, _, W, _, _, W, _, _, W, W, _, W, W, _, _, W],
  [_, W, W, _, _, W, _, W, W, _, W, _, _, W, W, W, W, W, W, W, _]
];

const linkedInWord = ({ _ = BACKGROUND, W = WORD } = {}): Word => [
  [W, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _],
  [W, _, _, W, _, _, _, W, _, _, _, _, _, _, _, W, _, W, _, _, _],
  [W, _, _, _, _, _, _, W, _, W, _, W, _, _, _, W, _, _, _, _, _],
  [W, _, _, W, W, W, _, W, W, _, W, W, W, W, W, W, _, W, W, W, W],
  [W, _, _, W, W, _, W, W, W, _, W, _, _, W, _, W, _, W, W, _, W],
  [W, W, W, W, W, _, W, W, _, W, _, W, W, W, W, W, _, W, W, _, W]
];

const cvWord = ({ _ = BACKGROUND, W = WORD } = {}): Word => [
  [_, _, _, _, _, _, W, W, W, _, _, W, _, _, _, W, _, _, _, _, _],
  [_, _, _, _, _, W, _, _, _, W, _, W, _, _, _, W, _, _, _, _, _],
  [_, _, _, _, _, W, _, _, _, _, _, _, W, _, W, _, _, _, _, _, _],
  [_, _, _, _, _, W, _, _, _, _, _, _, W, _, W, _, _, _, _, _, _],
  [_, _, _, _, _, W, _, _, _, W, _, _, _, W, _, _, _, _, _, _, _],
  [_, _, _, _, _, _, W, W, W, _, _, _, _, W, _, _, _, _, _, _, _]
];

const base = createCurriedPattern<Partial<NodePropertiesWithRelativeSize>>({
  type: 'solid',
  defaultValue: BACKGROUND,
  sequence2d: basePattern({ word: menuWord() }),
  alignColumns: 'center',
  alignRows: 'center'
});

const zones = createCurriedPattern({
  type: 'solid',
  defaultValue: -1,
  sequence2d: basePattern({
    _: -1,
    G: 0,
    g: 0,
    X: 0,

    L: 1,
    l: 1,
    Y: 1,

    C: 2,
    c: 2,
    Z: 2
  }),
  alignColumns: 'center',
  alignRows: 'center'
});

const githubHover = createCurriedPattern<
  Partial<NodePropertiesWithRelativeSize>
>({
  type: 'solid',
  defaultValue: BACKGROUND,
  sequence2d: basePattern({
    word: githubWord(),
    X: OUTLINE,
    G: { ...GITHUB_F, relativeSize: 1.1 },
    g: { ...GITHUB_B, relativeSize: 1.1 }
  }),
  alignColumns: 'center',
  alignRows: 'center'
});

const linkedInHover = createCurriedPattern<
  Partial<NodePropertiesWithRelativeSize>
>({
  type: 'solid',
  defaultValue: BACKGROUND,
  sequence2d: basePattern({
    word: linkedInWord(),
    Y: OUTLINE,
    L: { ...LINKED_F, relativeSize: 1.1 },
    l: { ...LINKED_B, relativeSize: 1.1 }
  }),
  alignColumns: 'center',
  alignRows: 'center'
});

const cvHover = createCurriedPattern<Partial<NodePropertiesWithRelativeSize>>({
  type: 'solid',
  defaultValue: BACKGROUND,
  sequence2d: basePattern({
    word: cvWord(),
    Z: OUTLINE,
    C: { ...CV_F, relativeSize: 1.1 },
    c: { ...CV_B, relativeSize: 1.1 }
  }),
  alignColumns: 'center',
  alignRows: 'center'
});

export const mainMenuSequence = menu({
  base,
  zones,

  zoneHandlers: [
    {
      hoverState: githubHover,
      onClick: () => window.open('https://github.com/andrewpmckenzie', '_blank')
    },
    {
      hoverState: linkedInHover,
      onClick: () =>
        window.open('https://www.linkedin.com/in/andrewpmckenzie', '_blank')
    },
    {
      hoverState: cvHover,
      onClick: () =>
        window.open('https://www.linkedin.com/in/andrewpmckenzie', '_blank')
    }
  ]
});
