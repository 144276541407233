import { Context, NodeProperties, Transition } from '../model';
import { debug } from 'debug';
import { transferNodeProperties } from '../util/transferNodeProperties';

const log = debug('viz:sequence2d:backAndForth');

const propertiesToTransfer = [
  'size',
  'color',
  'transitionTimeMs',
  'delayTimeMs'
  // No cx/cy
] as Array<string & keyof NodeProperties>;

export const backAndForth = ({
  speedMs = 500,
  iterations = 10
} = {}): Transition =>
  function*(context: Context) {
    context.easing = 'linear';

    // If an odd number of rows, the last row is ltr and we use the last item from the last row,
    // else it's rtl and we need the first item from the last row
    const lastNodeIndex =
      context.totalRows % 2 === 1
        ? context.nodes.length - 1
        : context.nodes.length - context.totalCols;

    // Calculated prior as we're doing the same calculation-intensive transitions over and over
    const transformations = context.nodes.map((n, i) => {
      const fromState: Partial<NodeProperties> & { cx: number; cy: number } = {
        cx: n.originalCx,
        cy: n.originalCy
      };
      transferNodeProperties(n, fromState, propertiesToTransfer);

      const toState = { ...fromState };

      let previousNodeIndex = 0;

      if (i === 0) {
        /**
         * Edge case: first node
         **/

        previousNodeIndex = lastNodeIndex;
        fromState.cy = 0 - n.originalCy;
      } else if (n.rowPos % 2 === 0) {
        /**
         * Left to right
         **/

        previousNodeIndex =
          n.colPos === 0
            ? /* first node of previous row */ i - context.totalCols
            : i - 1;
        const prevNode = context.nodes[previousNodeIndex];
        if (n.colPos === 0) {
          fromState.cy = prevNode.originalCy;
        } else {
          fromState.cx = prevNode.originalCx;
        }
      } else {
        /**
         * Right to left
         **/

        previousNodeIndex =
          n.colPos === context.totalCols - 1
            ? /* last node of previous row */ i - context.totalCols
            : i + 1;
        const prevNode = context.nodes[previousNodeIndex];
        if (n.colPos === context.totalCols - 1) {
          // Last node drops down
          fromState.cy = prevNode.originalCy;
        } else {
          fromState.cx = prevNode.originalCx;
        }
      }

      if (i === context.nodes.length - 1) {
        toState.color = context.backgroundColor;
      }

      return { fromState, toState, previousNodeIndex };
    });

    const updateStates = () => {
      transformations.forEach((t) => {
        transferNodeProperties(t.fromState, t.toState, propertiesToTransfer);
        transferNodeProperties(
          context.nodes[t.previousNodeIndex],
          t.fromState,
          propertiesToTransfer
        );
      });
    };

    for (let iteration = 0; iteration < iterations; iteration++) {
      log('advancing');
      updateStates();
      context.nodes.forEach((n, i) => {
        transferNodeProperties(
          transformations[i].fromState,
          n,
          propertiesToTransfer
        );
        n.cx = transformations[i].fromState.cx;
        n.cy = transformations[i].fromState.cy;
        n.transitionTimeMs = null;
      });
      context.transitionTimeMs = 0;
      yield;

      context.nodes.forEach((n, i) => {
        n.cx = transformations[i].toState.cx;
        n.cy = transformations[i].toState.cy;
      });
      context.transitionTimeMs = speedMs;
      yield;
    }
  };
