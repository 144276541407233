import { debug } from 'debug';
import { bond } from '../../../lib/transition/bond';
import { setState } from '../../../lib/transition/setState';
import {
  allAtOnce,
  AVOCADO,
  BLACK,
  BURNT_SIENNA,
  CHILI_PEPPER,
  Context,
  CORSAIR,
  createCurriedColorPattern,
  delay,
  linearProgress,
  MIMOSA,
  reset,
  RPC,
  RUST,
  shutter,
  Transition,
  TRANSPARENT,
  WHITE
} from '../../../lib';
import { createHi } from '../patterns/hi';
import { createInitials } from '../patterns/initials';
import { createNzMap } from '../patterns/nz';
import { createUsFlag } from '../patterns/usFlag';
import { createWebappStates } from '../patterns/webapp';

const log = debug('viz:sequence');

export function* masterSequence(context: Context) {
  const accentColor = [
    AVOCADO,
    CORSAIR,
    RUST,
    CHILI_PEPPER,
    BURNT_SIENNA,
    MIMOSA
  ][(Math.random() * 6) | 0];

  const { webappUp, webappDown } = createWebappStates(accentColor);
  const hi = createHi({ A: { color: accentColor }, F: { color: WHITE } });
  const hiDot = createHi({
    A: { color: accentColor, relativeSize: 1 },
    F: { color: TRANSPARENT }
  });
  const maxDim = Math.max(window.innerWidth, window.innerHeight) * 2;
  const hiFill = createHi({
    A: { color: accentColor, size: maxDim },
    F: { color: TRANSPARENT, transitionTimeMs: 500 }
  });
  const initials = createInitials({
    F: { color: WHITE }
  });
  const initialsAccent = createInitials({
    F: { color: accentColor },
    B: {}
  });
  const initials2 = createInitials({
    F: { relativeSize: 0.5 },
    B: {}
  });
  const initials3 = createInitials({
    F: { color: WHITE },
    B: {}
  });
  const usFlag = createUsFlag();
  const nzMap = createNzMap({
    _: { relativeSize: 0.3, color: TRANSPARENT },
    L: { relativeSize: 1.2, color: WHITE },
    M: { relativeSize: 0.8, color: WHITE },
    S: { relativeSize: 0.5, color: WHITE },
    D: { relativeSize: 0.5, color: AVOCADO }
  });

  const hiDotNode: { rowPos: number; colPos: number } = (() => {
    const rendered = hi(context.totalCols, context.totalRows);
    for (let i = 0; i < context.totalCols * context.totalRows; i++) {
      if (rendered.forPosition(i).color === accentColor) {
        return context.nodes[i];
      }
    }
    return { rowPos: 0, colPos: 0 };
  })();

  const bondStartCol = (() => {
    if (hiDotNode.colPos > 8) {
      return hiDotNode.colPos - 8;
    }
    return hiDotNode.colPos % 2 ? 1 : 0;
  })();

  const sequences: Array<Transition> = [
    setState({ narration: '' }),
    reset({ backgroundColor: BLACK }),

    bond(
      {
        ...hiDotNode,
        colPos: bondStartCol
      },
      hiDotNode,
      {
        skip: 1,
        speedMs: 700,
        active: { color: WHITE, relativeSize: 1.5 },
        inactive: { color: TRANSPARENT, relativeSize: 1 }
      }
    ),

    allAtOnce({ pattern: hiDot, transitionTimeMs: 1000 }),
    allAtOnce({ pattern: hi, transitionTimeMs: 1000 }),
    allAtOnce({
      pattern: hiFill,
      transitionTimeMs: 800,
      background: accentColor
    }),
    reset({ backgroundColor: accentColor }),

    setState({
      narration: "I'm Andrew"
    }),
    allAtOnce({
      pattern: initials,
      transitionTimeMs: 1000
    }),
    delay(1000),

    setState({
      narration: 'A Kiwi,'
    }),
    allAtOnce({
      pattern: nzMap,
      transitionTimeMs: 1000
    }),
    allAtOnce({
      background: BLACK,
      transitionTimeMs: 1500
    }),

    setState({
      narration: 'living in the US'
    }),
    allAtOnce({
      background: BLACK,
      pattern: usFlag,
      transitionTimeMs: 2000
    }),
    delay(1000),

    reset(),

    setState({
      narration: 'I make apps for the web'
    }),
    allAtOnce({
      pattern: webappUp,
      transitionTimeMs: 500
    }),
    delay(500),
    allAtOnce({
      pattern: webappDown,
      transitionTimeMs: 200
    }),
    delay(200),
    allAtOnce({
      pattern: webappUp,
      transitionTimeMs: 300
    }),
    delay(500),

    reset(),

    //setState({ narration: 'Using awesome tools' }),
    //delay(1000),

    //setState({ narration: 'For some great companies' }),
    //delay(1000),

    setState({
      narration: 'And might finish this site some day'
    }),
    bond(
      {
        colPos: (context.totalCols / 2 - 2) | 0,
        rowPos: (context.totalRows / 2) | 0
      },
      {
        colPos: (context.totalCols / 2 + 2) | 0,
        rowPos: (context.totalRows / 2) | 0
      },
      {
        active: { color: WHITE, relativeSize: 1.5 },
        inactive: { color: WHITE, relativeSize: 1.5 },
        speedMs: 700,
        skip: 1
      }
    ),
    delay(1000),

    reset(),

    setState({ rpc: RPC.HANDLE_MAIN_SEQUENCE_ENDED, narration: '' }),

    allAtOnce({
      background: BLACK,
      pattern: initialsAccent,
      transitionTimeMs: 500
    }),

    delay(2000),

    allAtOnce({
      background: BLACK,
      pattern: initials2,
      transitionTimeMs: 2000
    }),

    shutter([
      'rgba(255, 255, 255, 0.1)',
      'rgba(255, 255, 255, 0.2)',
      'rgba(255, 255, 255, 0.3)',
      'rgba(255, 255, 255, 0.2)'
    ]),

    allAtOnce({
      background: null,
      pattern: initials3,
      transitionTimeMs: 500
    }),

    delay(5000),

    linearProgress(
      accentColor,
      createCurriedColorPattern({
        type: 'wrap',
        sequence1d: [
          'rgba(255, 255, 255, 0.1)',
          'rgba(255, 255, 255, 0.2)',
          'rgba(255, 255, 255, 0.3)',
          'rgba(255, 255, 255, 0.4)',
          'rgba(255, 255, 255, 0.5)'
        ]
      }),
      200
    ),

    delay(5000),

    allAtOnce({
      background: null,
      pattern: initials3,
      transitionTimeMs: 500
    })
  ];

  for (let sequence of sequences) {
    log('starting new sequence');
    yield* sequence(context);
  }
}
