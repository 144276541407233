// Pantone's colors of the 70s
// https://www.pantone.com/pages/pantone.aspx?pg=21126
export const AVOCADO = '#676232';
export const BURNT_SIENNA = '#C65D52';
export const CARAFE = '#5D473A';
export const HARVEST_GOLD = '#B68A3A';
export const RUST = '#B55A30';
export const CORSAIR = '#18576C';

// Pantone's colors of the 00s
export const CERULEAN = '#9BB7D4';
export const CHILI_PEPPER = '#9B1B30';
export const SATELLITE = '#978D89';
export const LAVENDER_AURA = '#9F99AA';
export const MIMOSA = '#F0C05A';
export const ASPEN_GREEN = '#7E9B76';

// Base colors
export const OFF_WHITE = '#f5f5dc';
export const WHITE = OFF_WHITE;
export const RED = CHILI_PEPPER;
export const BLUE = CORSAIR;

export const BLACK = 'black';
export const TRANSPARENT = 'transparent';
