import {
  BLUE,
  RED,
  TRANSPARENT,
  WHITE,
  createCurriedColorPattern
} from '../../../lib';

// prettier-ignore
export const createUsFlag = ({
  R = RED,
  B = BLUE,
  W = WHITE
} = {}) => createCurriedColorPattern({
  type: 'solid',
  defaultValue: TRANSPARENT,
  alignColumns: 'center',
  alignRows: 'center',
  sequence2d: [
    [B, W, B, W, B, W, B, B, R, R, R, R, R, R, R, R, R, R, R, R,],
    [W, B, W, B, W, B, W, B, W, W, W, W, W, W, W, W, W, W, W, W,],
    [B, W, B, W, B, W, B, W, R, R, R, R, R, R, R, R, R, R, R, R,],
    [W, B, W, B, W, B, W, B, W, W, W, W, W, W, W, W, W, W, W, W,],
    [B, W, B, W, B, W, B, W, R, R, R, R, R, R, R, R, R, R, R, R,],
    [W, B, W, B, W, B, W, B, W, W, W, W, W, W, W, W, W, W, W, W,],
    [B, W, B, W, B, W, B, B, R, R, R, R, R, R, R, R, R, R, R, R,],
    [W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W,],
    [R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R,],
    [W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W,],
    [R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R,],
    [W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W, W,],
    [R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R, R,],
  ]
});
