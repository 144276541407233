import React, { useContext, useState } from 'react';
import styled from 'styled-components';

const Node = styled.div``;

const PADDING_PX = 12;

// prettier-ignore
const Icon = styled.div<{ thickness: number; size: number; color: string }>`
  box-sizing: border-box;
  height: ${(p) => p.size}px;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: ${(p) => p.size}px;

  ${Node} {
    border-bottom: solid ${(p) => p.thickness}px ${(p) => p.color};
    left: -${PADDING_PX / 4}px;
    position: absolute;
    right: -${PADDING_PX / 4}px;
    transition: all 0.2s ease-in-out;
  }

  &.hamburger {
    ${Node}:nth-child(1) {
      top: -${(p) => (p.thickness / 2)}px;
    }

    ${Node}:nth-child(2) {
      margin-top: -${(p) => (p.thickness / 2)}px;
      top: 50%;
    }

    ${Node}:nth-child(3) {
      bottom: -${(p) => (p.thickness / 2)}px;
    }
  }

  &.x {
    ${Node}:nth-child(1) {
      margin-top: -${(p) => (p.thickness / 2)}px;
      transform: rotate(45deg);
      transition-delay: 0.1s;
      top: 50%;
    }

    ${Node}:nth-child(2) {
      left: 50%;
      margin-top: -${(p) => (p.thickness / 2)}px;
      right: 50%;
      top: 50%;
    }

    ${Node}:nth-child(3) {
      bottom: 50%;
      margin-bottom: -${(p) => (p.thickness / 2)}px;
      transform: rotate(-45deg);
      transition-delay: 0.1s;
    }
  }
  
  &.back {
    ${Node}:nth-child(1) {
      margin-top: -${(p) => (p.thickness / 2)}px;
      right: 50%;
      transform: rotate(-45deg);
      transform-origin: left;
      top: 50%;
    }

    ${Node}:nth-child(2) {
      margin-top: -${(p) => (p.thickness / 2)}px;
      top: 50%;
    }

    ${Node}:nth-child(3) {
      bottom: 50%;
      margin-bottom: -${(p) => (p.thickness / 2)}px;
      right: 50%;
      transform: rotate(45deg);
      transform-origin: left;
    }
  }
}
`;

const Button = styled.button`
  background: rgba(0, 0, 0, 0);
  border: solid transparent 1px;
  border-radius: 100%;
  padding: ${PADDING_PX}px;
  position: relative;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.1);
    border: solid rgba(255, 255, 255, 0.1) 1px;
    outline: 0;
  }

  &:active {
    background: rgba(0, 0, 0, 0.2);
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const Container = styled.div<{
  backgroundColor: string;
  underlineColor: string;
}>`
  align-items: center;
  background-color: ${(p) => p.backgroundColor};
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom: solid 1px ${(p) => p.underlineColor};
  z-index: 10;

  @media print {
    display: none;
  }
`;

const Title = styled.div<{}>`
  font-family: 'Special Elite', 'Monoton', sans-serif;
  font-size: 1.5rem;
  font-weight: 100;
  margin-right: 16px;
  margin-top: 12px;
`;

export type HeaderProps = {
  iconForm: 'x' | 'back' | 'hamburger';
  onClick: () => void;
  color?: string;
  backgroundColor?: string;
  underlineColor?: string;
  title?: string;
};

const defaultState = {
  iconForm: 'hamburger' as HeaderProps['iconForm'],
  onClick: () => {},
  color: 'rgba(128, 128, 128, 0.2)',
  backgroundColor: 'transparent',
  underlineColor: 'transparent',
  title: ''
};

const Context = React.createContext<{
  state: HeaderProps;
  setState: (state: HeaderProps) => void;
}>({
  state: defaultState,
  setState: () => {}
});

const HeaderBar: React.FC<{}> = () => {
  const { state } = useContext(Context);
  const { backgroundColor, color, onClick, iconForm, underlineColor, title } = {
    ...defaultState,
    ...state
  };
  return (
    <Container
      backgroundColor={backgroundColor}
      underlineColor={underlineColor}
    >
      <Button onClick={() => onClick()}>
        <Icon className={iconForm} thickness={3} size={16} color={color}>
          <Node />
          <Node />
          <Node />
        </Icon>
      </Button>
      {title && <Title>{title}</Title>}
    </Container>
  );
};

export const useHeader = (props: HeaderProps) => {
  const { setState } = useContext(Context);
  setState(props);
};

export const Header: React.FC<{}> = ({ children }) => {
  const [state, setState] = useState<HeaderProps>(defaultState);
  return (
    <Context.Provider value={{ state, setState }}>
      <HeaderBar />
      {children}
    </Context.Provider>
  );
};
