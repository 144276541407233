import {
  CORSAIR,
  TRANSPARENT,
  createCurriedPattern,
  NodePropertiesWithRelativeSize
} from '../../../lib';

type PNP = Partial<NodePropertiesWithRelativeSize>;

// prettier-ignore
export const createInitials = ({
  B/*ACKGROUND*/ = {color: TRANSPARENT} as PNP,
  F/*OREGROUND*/ = {color: CORSAIR} as PNP,
} = {}) => createCurriedPattern({
  type: 'solid',
  defaultValue: B,
  sequence2d: [
    [B, B, F, F, B, B, B, B, B, B, B],
    [B, F, B, F, B, F, F, F, B, B, B],
    [F, B, B, F, B, F, B, F, F, F, B],
    [F, B, B, F, B, F, B, F, B, F, B],
    [F, F, F, F, B, F, B, F, B, F, B],
    [F, B, B, F, B, F, B, B, B, F, B],
    [F, B, B, F, B, F, B, B, B, F, B],
    [F, B, B, F, B, F, B, B, B, F, B],
    [F, B, B, F, B, F, B, B, B, F, B],
  ],
  alignRows: 'center',
  alignColumns: 'center',
});
