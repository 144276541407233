import { easeBackInOut, easeLinear } from 'd3';
import { State } from '../model';
import { stateDiffers } from '../util/stateDiffers';
import { Renderer } from '../model';
import * as d3 from 'd3';
import { debug } from 'debug';

const log = debug('viz:renderers:d3svg');

export const d3Svg: Renderer = (
  container,
  context,
  sequence,
  onStateChange
) => {
  // prettier-ignore
  const canvas = d3.select(container)
      .append('svg')
      .style('width', `${context.dimensionsPx.width}px`)
      .style('height', `${context.dimensionsPx.height}px`)
      .style('background-color', context.backgroundColor);

  const nodeContainer = canvas.append('g').attr('id', 'nodes');
  const nodes = nodeContainer
    .selectAll('circle')
    .data(context.nodes)
    .enter()
    .append('circle')
    .style('transition-property', 'fill, width, height, r, transform')
    .style('fill', (node) => node.color)
    .style('r', (node) => node.size / 2)
    .attr('cx', (node) => node.cx)
    .attr('cy', (node) => node.cy);

  // Event listeners
  const listenerSize = context.standardNodeSize + context.gapSize;
  canvas
    .append('g')
    .attr('id', 'event-listeners')
    .selectAll('rect')
    .data(context.nodes)
    .enter()
    .append('rect')
    .attr('fill', 'transparent')
    .attr('x', (n) => n.colPos * listenerSize)
    .attr('width', listenerSize)
    .attr('y', (n) => n.rowPos * listenerSize)
    .attr('height', listenerSize)
    .on('mouseenter', (n) => n.onMouseEnter && n.onMouseEnter(n, d3.event))
    .on('touchstart', (n) => n.onMouseEnter && n.onMouseEnter(n, d3.event))
    .on('mouseleave', (n) => n.onMouseLeave && n.onMouseLeave(n, d3.event))
    .on('toucheend', (n) => n.onMouseLeave && n.onMouseLeave(n, d3.event))
    .on('click', (n) => n.onClick && n.onClick(n, d3.event));

  let resolveCssTransitionPromise = () => {};
  let cssTransitionPromise = Promise.resolve();
  const cssTransitioningNodes = new Set();
  nodes.on('transitionrun', (node, i) => {
    cssTransitioningNodes.add(i);
    if (!cssTransitionPromise) {
      cssTransitionPromise = new Promise<void>((r) => {
        resolveCssTransitionPromise = r;
      });
    }
  });
  nodes.on('transitionend', (node, i) => {
    cssTransitioningNodes.delete(i);
    resolveCssTransitionPromise();
    cssTransitionPromise = Promise.resolve();
    resolveCssTransitionPromise = () => {};
  });

  nodes.exit().remove();

  const transformCanvas = () =>
    canvas
      .transition()
      .duration(context.transitionTimeMs)
      .style('background-color', context.backgroundColor)
      .end();

  const transformNodes = () => {
    /* CSS Transitions */
    nodes
      .style(
        'transition-duration',
        (node) =>
          `${(node.transitionTimeMs === null
            ? context.transitionTimeMs
            : node.transitionTimeMs) + /* "0" invokes a bad bug */ 1}ms`
      )
      .style('transition-delay', (node) => `${node.delayTimeMs}ms`)
      .style('transition-timing-function', () => {
        switch (context.easing) {
          case 'linear':
            return 'linear';
          default:
            return 'ease-in-out';
        }
      })
      .style('fill', (node) => node.color)
      .style('r', (node) => node.size / 2);

    /* D3 Tween Transitions */
    const tweenTransitions = nodes
      .filter(
        (node, i, array) =>
          node.cx !== array[i]!.cx.baseVal.value ||
          node.cy !== array[i]!.cy.baseVal.value
      )
      .transition()
      .duration((node) =>
        node.transitionTimeMs === null
          ? context.transitionTimeMs
          : node.transitionTimeMs
      )
      .delay((node) => node.delayTimeMs)
      .ease(
        (() => {
          switch (context.easing) {
            case 'default':
              return easeBackInOut;
            case 'linear':
              return easeLinear;
          }
        })()
      )
      .attr('cx', (node) => node.cx)
      .attr('cy', (node) => node.cy);

    return tweenTransitions.end().then(() => cssTransitionPromise);
  };

  let lastState = {} as State;
  const run = () => {
    let isRunning = true;

    (async () => {
      do {
        const result = sequence.next();
        if (result.done) {
          break;
        }

        log('step()');
        const newState = context.state;
        if (stateDiffers(lastState, newState)) {
          onStateChange(newState);
          lastState = newState;
        }

        if (result.value) {
          // Ignore these promises
          transformNodes().catch(() => log('Rendering interrupted.'));
          transformCanvas().catch(() => log('Rendering interrupted.'));

          // Yield returned a promise which has the ability to interrupt
          // any transition (or delay beyond it)
          await result.value;
          // Kill any tween in progress
          canvas.interrupt();
        } else {
          await Promise.all([transformNodes(), transformCanvas()]).catch(() =>
            log('Rendering interrupted.')
          );
        }
      } while (isRunning);
    })();

    return () => {
      isRunning = false;
      log('stop()');
    };
  };

  const stop = run();

  const destroy = () => {
    log('destroy()');
    stop();
    canvas.interrupt();
  };

  return { destroy };
};
