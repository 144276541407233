import { sequences, SequenceType } from './sequence';
import { StateChangeHandler, Context, renderer } from '../../lib';

let contextCache: [{ width: number; height: number }, Context] | null = null;

export const render = (
  sequenceType: SequenceType,
  container: HTMLDivElement,
  onStateChange: StateChangeHandler,
  dimensions: { width: number; height: number },
  contextOpts: {
    minRows: number;
    minCols: number;
    maxSize: number;
    gap: number;
  }
) => {
  if (
    !contextCache ||
    contextCache![0].width !== dimensions.width ||
    contextCache![0].height !== dimensions.height
  ) {
    const context = new Context(dimensions, contextOpts);
    contextCache = [dimensions, context];
  }

  const context = contextCache![1];
  const sequence = sequences[sequenceType](context);
  const destroy = renderer(container, context, sequence, onStateChange).destroy;
  return { destroy };
};
