import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

const DEBOUNCE_TIME_MS = 500;

export const useWindowSize = () => {
  // window.innerWidth etc doesn't work well with mobile. This is what jquery opts for.
  const [windowSize, setWindowSize] = useState<{
    width: number | null;
    height: number | null;
  }>({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    const duringResize = debounce(
      () =>
        setWindowSize({
          width: null,
          height: null
        }),
      DEBOUNCE_TIME_MS,
      {
        leading: true,
        trailing: false
      }
    );
    const afterResize = debounce(
      () =>
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        }),
      DEBOUNCE_TIME_MS,
      {
        trailing: true
      }
    );
    window.addEventListener('resize', duringResize);
    window.addEventListener('resize', afterResize);
    return () => {
      window.removeEventListener('resize', duringResize);
      window.removeEventListener('resize', afterResize);
    };
  }, []);
  return windowSize;
};
