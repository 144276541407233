import { TRANSPARENT } from '../pallette';
import { transferNodeProperties } from '../util/transferNodeProperties';

export interface NodeProperties {
  size: number;
  color: string;
  cx: number;
  cy: number;
  transitionTimeMs: number | null; // null reverts to context default
  delayTimeMs: number;
  onMouseEnter: ((n: Node, e: MouseEvent) => void) | null;
  onMouseLeave: ((n: Node, e: MouseEvent) => void) | null;
  onClick: ((n: Node, e: MouseEvent) => void) | null;
}

export class Node implements NodeProperties {
  public size!: number;
  public color!: string;
  public cx!: number;
  public cy!: number;
  public transitionTimeMs!: number | null; // null reverts to context default
  public delayTimeMs!: number;
  public onMouseEnter!: ((n: Node, e: MouseEvent) => void) | null;
  public onMouseLeave!: ((n: Node, e: MouseEvent) => void) | null;
  public onClick!: ((n: Node, e: MouseEvent) => void) | null;

  constructor(
    public rowPos: Readonly<number>,
    public colPos: Readonly<number>,
    public originalSize: Readonly<number>,
    windowSizePx: { width: number; height: number },
    totalRows: number,
    totalCols: number,
    public originalCx: Readonly<number> = Node.calcDistance(
      windowSizePx.width,
      colPos,
      totalCols
    ),
    public originalCy: Readonly<number> = Node.calcDistance(
      windowSizePx.height,
      rowPos,
      totalRows
    )
  ) {
    this.reset();
  }

  reset(overrides: Partial<NodeProperties> = {}) {
    const defaults: NodeProperties = {
      size: this.originalSize,
      color: TRANSPARENT,
      cx: this.originalCx,
      cy: this.originalCy,
      transitionTimeMs: null,
      delayTimeMs: 0,
      onMouseEnter: null,
      onMouseLeave: null,
      onClick: null
    };
    transferNodeProperties({ ...defaults, ...overrides }, this);
  }

  private static calcDistance(distance: number, index: number, total: number) {
    return ((index + 0.5) / total) * distance;
  }
}
