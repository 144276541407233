import { AbstractPattern } from './AbstractPattern';
import { Pattern } from './Pattern';

export type SolidPatternOptions<T> = {
  sequence2d: T[][];
  alignRows: 'top' | 'center' | 'bottom';
  alignColumns: 'left' | 'center' | 'right';
  rowOffset: number;
  columnOffset: number;
  defaultValue: T;
};

export class SolidPattern<T> extends AbstractPattern<T> implements Pattern<T> {
  private rowOffset = this.calcRowOffset();

  constructor(
    numCanvasCols: number,
    numCanvasRows: number,
    private opts: SolidPatternOptions<T>
  ) {
    super(numCanvasCols, numCanvasRows);
  }

  forCoordinates(canvasCol: number, canvasRow: number): T {
    const normalizedRowPos = canvasRow - this.rowOffset;
    const row = this.opts.sequence2d[normalizedRowPos];
    if (!row) {
      return this.opts.defaultValue;
    }

    const colOffset = this.calcColOffset(row.length);
    const normalizedColPos = canvasCol - colOffset;
    return row[normalizedColPos] != null
      ? row[normalizedColPos]
      : this.opts.defaultValue;
  }

  private calcRowOffset() {
    const canvasRows = this.numCanvasRows;
    const sequenceRows = this.opts.sequence2d.length;
    switch (this.opts.alignRows) {
      case 'center':
        if (this.opts.rowOffset > 0) {
          throw new Error('rowOffset must be 0 when alignRows=center');
        }
        return Math.ceil((canvasRows - sequenceRows) / 2);
      case 'bottom':
        return canvasRows - sequenceRows - this.opts.rowOffset;
      case 'top':
        return this.opts.rowOffset;
      default:
        throw new Error('Unexpected case');
    }
  }

  private colOffsetMemo = new Map<number, number>();
  private calcColOffset(sequenceColumns: number) {
    const canvasColumns = this.numCanvasCols;
    const memo = this.colOffsetMemo.get(sequenceColumns);
    if (memo != null) {
      return memo;
    }

    const value = (() => {
      switch (this.opts.alignColumns) {
        case 'center':
          if (this.opts.columnOffset > 0) {
            throw new Error('colOffset must be 0 when alignColumns=center');
          }
          return Math.ceil((canvasColumns - sequenceColumns) / 2);
        case 'right':
          return canvasColumns - sequenceColumns - this.opts.columnOffset;
        default:
          return this.opts.columnOffset;
      }
    })();
    this.colOffsetMemo.set(sequenceColumns, value);
    return value;
  }
}
