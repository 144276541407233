import {
  NodePropertiesWithRelativeSize,
  TRANSPARENT,
  WHITE,
  createCurriedPattern,
  SATELLITE,
  ASPEN_GREEN
} from '../../../lib';

// prettier-ignore
export const createWebapp = ({
  _ = {color: TRANSPARENT} as Partial<NodePropertiesWithRelativeSize>,
  H = {color: WHITE} as Partial<NodePropertiesWithRelativeSize>,
  G = {color: ASPEN_GREEN} as Partial<NodePropertiesWithRelativeSize>,
  U = {color: SATELLITE} as Partial<NodePropertiesWithRelativeSize>,
  D = {color: SATELLITE, relativeSize: 0.5} as Partial<NodePropertiesWithRelativeSize>,
} = {}) => createCurriedPattern({
  type: 'solid',
  defaultValue: _,
  sequence2d: [
    [_, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, _, ],
    [_, _, _, _, U, U, U, U, U, U, U, U, U, _, _, _, _, _, _, ],
    [_, _, _, U, U, G, G, G, G, G, G, G, G, G, _, _, _, _, _, ],
    [_, _, _, U, G, G, G, G, H, H, G, G, G, G, D, _, _, _, _, ],
    [_, _, _, _, G, G, G, G, H, H, G, G, G, D, D, _, _, _, _, ],
    [_, _, _, _, _, D, D, D, H, H, D, D, D, D, _, _, _, _, _, ],
    [_, _, _, _, _, _, _, _, H, H, _, _, _, _, _, _, _, _, _, ],
    [_, _, _, _, _, _, _, _, H, H, H, H, _, _, _, _, _, _, _, ],
    [_, _, _, _, _, _, _, _, H, H, H, H, H, H, _, _, _, _, _, ],
    [_, _, _, _, _, H, H, _, H, H, H, H, H, H, H, H, _, _, _, ],
    [_, _, _, _, _, H, H, H, H, H, H, H, H, H, H, H, _, _, _, ],
    [_, _, _, _, _, _, H, H, H, H, H, H, H, H, H, H, _, _, _, ],
    [_, _, _, _, _, _, H, H, H, H, H, H, H, H, H, H, _, _, _, ],
    [_, _, _, _, _, _, _, H, H, H, H, H, H, H, H, H, _, _, _, ],
    [_, _, _, _, _, _, _, _, H, H, H, H, H, H, H, _, _, _, _, ],
    [_, _, _, _, _, _, _, _, H, H, H, H, H, H, _, _, _, _, _, ],
  ],
  alignRows: 'center',
  alignColumns: 'center',
});

export const createWebappStates = (color = 'red') => {
  const button = { color, relativeSize: 1 };
  const shadow = { color: SATELLITE, relativeSize: 0.5 };
  const blank = { color: TRANSPARENT, relativeSize: 1 };
  const webappUp = createWebapp({
    G: button,
    U: button,
    D: shadow
  });
  const webappDown = createWebapp({
    U: blank,
    G: button,
    D: button
  });
  return { webappUp, webappDown };
};
