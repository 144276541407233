import {
  NodePropertiesWithRelativeSize,
  BURNT_SIENNA,
  TRANSPARENT,
  WHITE,
  createCurriedPattern
} from '../../../lib';

// prettier-ignore
export const createHi = ({
  B/*ACKGROUND*/ = {color: TRANSPARENT} as Partial<NodePropertiesWithRelativeSize>,
  F/*OREGROUND*/ = {color: WHITE} as Partial<NodePropertiesWithRelativeSize>,
  A/*CCENT*/ = {color: BURNT_SIENNA} as Partial<NodePropertiesWithRelativeSize>
} = {}) => createCurriedPattern({
  type: 'solid',
  defaultValue: B,
  sequence2d: [
    [F, B, B, B, B, B],
    [F, B, B, B, B, A],
    [F, B, B, B, B, B],
    [F, F, F, F, B, F],
    [F, B, B, F, B, F],
    [F, B, B, F, B, F],
    [F, B, B, F, B, F],
    [F, B, B, F, B, F],
    [F, B, B, F, B, F],
  ],
  alignRows: 'center',
  alignColumns: 'center',
  //columnOffset: 2,
  //rowOffset: 2
});
