import { Context, Transition } from '../model';
import { TRANSPARENT, WHITE } from '../pallette';
import { NodePropertiesWithRelativeSize } from '../util';
import { transferNodePropertiesWithRelativeSize } from '../util/transferNodeProperties';

type Pos = { rowPos: number; colPos: number };

export const bond = (
  from: Pos,
  to: Pos,
  {
    speedMs = 200,
    active = { color: WHITE } as Partial<NodePropertiesWithRelativeSize>,
    inactive = { color: TRANSPARENT } as Partial<
      NodePropertiesWithRelativeSize
    >,
    skip = 0
  } = {}
): Transition =>
  function*(context: Context) {
    const rowMovement = to.rowPos - from.rowPos;
    const colMovement = to.colPos - from.colPos;

    const steps = Math.max(Math.abs(rowMovement), Math.abs(colMovement));
    context.transitionTimeMs = speedMs;

    yield;

    let lastActiveRow: Pos | null = null;

    for (let i = 0; i <= steps; i = i + 1 + skip) {
      const progress = i / steps;
      const rowPos = from.rowPos + progress * rowMovement;
      const colPos = from.colPos + progress * colMovement;

      transferNodePropertiesWithRelativeSize(
        active,
        context.getNodeAt({ rowPos, colPos }),
        context
      );

      if (lastActiveRow) {
        transferNodePropertiesWithRelativeSize(
          inactive,
          context.getNodeAt(lastActiveRow),
          context
        );
      }

      lastActiveRow = { rowPos, colPos };
      yield;
    }
  };
