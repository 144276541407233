import React, { useEffect, useRef } from 'react';
import { render } from './render';
import { SequenceType } from './sequence';
import { useWindowSize } from '../../lib/util/useWindowSize';

import { State, StateChangeHandler } from '../../lib';
export type ShowtimeState = State;

export const Showtime: React.FC<{
  onStateChange: StateChangeHandler;
  sequence: SequenceType;
}> = ({ onStateChange, sequence }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (!containerRef.current) {
      throw new Error('ContainerRef is null');
    }

    // Window adjustment is happening
    if (height === null || width === null) {
      containerRef.current.innerHTML = '';
      onStateChange({ rpc: null, narration: '' });
      return;
    }

    containerRef.current.style.backgroundColor = 'transparent';

    const { destroy } = render(
      sequence,
      containerRef.current,
      onStateChange,
      { width, height },
      {
        minRows: 30,
        minCols: 30,
        maxSize: 50,
        gap: 5
      }
    );

    return destroy;
  }, [containerRef, sequence, onStateChange, width, height]);

  return <div key={`${width}x${height}`} ref={containerRef} />;
};
