import { Context, Transition } from '../model';
import { debug } from 'debug';
import { createCurriedColorPattern, CurriedPattern } from '../pattern';

const log = debug('viz:sequence2d:shutter');

export const shutter = (
  colorPattern: CurriedPattern<{ color: string }> | string[]
): Transition =>
  function*(context: Context) {
    const pattern = (colorPattern instanceof Array
      ? createCurriedColorPattern({ type: 'shutter', sequence1d: colorPattern })
      : colorPattern)(context.totalCols, context.totalRows);

    context.transitionTimeMs = 500;
    const steps = Math.ceil(Math.min(context.totalCols, context.totalRows) / 2);
    log('Running in %s steps.', steps);
    for (let i = 0; i < steps; i++) {
      context.nodes.forEach((n) => {
        if (
          Math.min(
            n.colPos,
            n.rowPos,
            context.totalCols - 1 - n.colPos,
            context.totalRows - 1 - n.rowPos
          ) === i
        ) {
          n.color = pattern.forNode(n).color;
        }
      });

      yield;
    }
  };
