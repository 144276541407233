import {
  createCurriedPattern,
  NodePropertiesWithRelativeSize,
  CORSAIR,
  WHITE
} from '../../../lib';

type PNP = Partial<NodePropertiesWithRelativeSize>;

// prettier-ignore
export const createNzMap = ({
  L /*ARGE*/ = { relativeSize: 0.8, color: WHITE   } as PNP,
  M /*ED*/ =   { relativeSize: 0.6, color: WHITE   } as PNP,
  S /*MALL*/ = { relativeSize: 0.3, color: WHITE   } as PNP,
  D /*UN*/ = S as PNP,
  _ /* BG */ = { relativeSize: 0.5, color: CORSAIR } as PNP
} = {}) =>
  createCurriedPattern<PNP>({
    type: 'solid',
    sequence2d: [
      [_, _, _,   _, M, _,   _, _, _],
      [_, _, _,   _, S, M,   _, _, _],
      [_, _, _,   _, _, M,   S, _, _],
                                    //
      [_, _, _,   _, _, M,   L, M, S],
      [_, _, _,   _, S, M,   L, L, _],
      [_, _, _,   _, _, S,   L, S, _],
                                    //
      [_, _, _,   S, M, _,   M, _, _],
      [_, _, _,   M, L, S,   _, _, _],
      [_, _, S,   L, M, _,   _, _, _],
                                    //
      [_, M, L,   M, _, _,   _, _, _],
      [S, L, M,   D, _, _,   _, _, _],
      [_, S, S,   _, _, _,   _, _, _],
                                    //
      [M, _, _,   _, _, _,   _, _, _]
    ],
    alignRows: 'center',
    alignColumns: 'center',
    defaultValue: _
  });
