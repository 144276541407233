import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';
import { BLACK, WHITE } from '../lib';

const colorForBackground = {
  dark: WHITE,
  light: BLACK
};
type backgroundShades = keyof typeof colorForBackground;

const PositionContainer = styled.div`
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;

  &.visible-enter {
    opacity: 0;
  }

  &.visible-enter-active {
    opacity: 1;
    transition: opacity 0.4s;
  }

  &.visible-exit {
    opacity: 1;
  }

  &.visible-exit-active {
    opacity: 0;
    transition: opacity 0.2s;
  }
`;

const InnerContainer = styled.div<{ background: backgroundShades }>`
  border-radius: 4px;
  color: ${(p) => colorForBackground[p.background]};
  font-family: 'Special Elite', 'Monoton', sans-serif;
  font-size: 4em;
  font-weight: 100;
  padding: 12px 24px;
  margin: 8px;

  @media (max-height: 600px) {
    font-size: 2em;
  }

  @media (max-width: 600px) {
    font-size: 2em;
  }
`;

export const Narrator: React.FC<{
  script: string;
  background?: backgroundShades;
}> = ({ script, background = 'dark' }) => {
  return (
    <SwitchTransition>
      <CSSTransition key={script} timeout={500} classNames="visible" in={true}>
        <PositionContainer>
          <InnerContainer background={background}>{script}</InnerContainer>
        </PositionContainer>
      </CSSTransition>
    </SwitchTransition>
  );
};
