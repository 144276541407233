import { Context, Node, Transition } from '../model';
import { debug } from 'debug';
import { CurriedPattern } from '../pattern';
import {
  NodePropertiesWithRelativeSize,
  transferNodePropertiesWithRelativeSize
} from '../util/transferNodeProperties';

const log = debug('viz:sequence:menu');

type ZoneHandler = {
  hoverState: PropsPattern;
  onClick?: (n: Node, e: MouseEvent) => void;
};

type PropsPattern = CurriedPattern<Partial<NodePropertiesWithRelativeSize>>;

export const menu = ({
  base,
  zones,
  zoneHandlers
}: {
  base: PropsPattern;
  zones: CurriedPattern<number>;
  zoneHandlers: Array<ZoneHandler>;
}): Transition =>
  function*(context: Context) {
    const basePattern = base(context.totalCols, context.totalRows);
    const zonesPattern = zones(context.totalCols, context.totalRows);
    const zoneHandlerPatterns = zoneHandlers.map((z) => ({
      ...z,
      hoverPattern: z.hoverState(context.totalCols, context.totalRows)
    }));

    context.reset();
    context.transitionTimeMs = 200;

    let active = { index: -1, zone: -1 };
    let render = () => {};
    context.nodes.forEach((n, index) => {
      const zone = zonesPattern.forNode(n);
      n.onMouseEnter = () => {
        const zoneChanged = zone !== active.zone;
        active = { index, zone };
        if (zoneChanged) {
          log(
            'Invoking render loop (mouseEnter index %s, zone %s)',
            index,
            zone
          );
          render();
        }
      };
      n.onMouseLeave = () => {
        //if (active.index === index) {
        //  active = { index: -1, zone: -1 };
        //  log('Invoking render loop (mouseLeave index %s)', index);
        //  render();
        //}
      };
      n.onClick = (n, e) => {
        const zoneHandler = zoneHandlerPatterns[zone];
        if (zoneHandler && zoneHandler.onClick) {
          zoneHandler.onClick(n, e);
          log('Invoking render loop (click index %s, zone %s)', index, zone);
          render();
        }
      };
    });

    while (true) {
      log('Rendering. Active index %s, zone %s', active.index, active.zone);

      const pattern = zoneHandlerPatterns[active.zone]
        ? zoneHandlerPatterns[active.zone].hoverPattern
        : basePattern;
      context.nodes.forEach((n) => {
        transferNodePropertiesWithRelativeSize(pattern.forNode(n), n, context, [
          'color',
          'cx',
          'cy',
          'size'
        ]);
      });

      // Render loop is kicked off by mouse events
      // eslint-disable-next-line
      yield new Promise((r) => (render = r));
    }
  };
