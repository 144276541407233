import { range } from 'd3-array';
import { Node } from '../model';

const coordinateMapCache = new Map<
  string,
  Array<{ row: number; col: number }>
>();

const constructCoordinateMap = (numCols: number, numRows: number) => {
  const key = `${numCols}x${numRows}`;
  const valFromCache = coordinateMapCache.get(key);
  if (valFromCache) {
    return valFromCache;
  }

  const val = range(numCols * numRows).map((i) => {
    const row = Math.floor(i / numCols);
    const col = i % numCols;
    return { row, col };
  });

  coordinateMapCache.set(key, val);
  return val;
};

export abstract class AbstractPattern<T> {
  private canvasCoordinateMap = constructCoordinateMap(
    this.numCanvasCols,
    this.numCanvasRows
  );

  protected constructor(
    protected numCanvasCols: number,
    protected numCanvasRows: number
  ) {}

  abstract forCoordinates(canvasCol: number, canvasRow: number): T;

  forPosition(i: number): T {
    const { col, row } = this.canvasCoordinateMap[i];
    return this.cachedForCoordinates(col, row);
  }

  forNode(n: Node): T {
    return this.cachedForCoordinates(n.colPos, n.rowPos);
  }

  protected toIndex(canvasCol: number, canvasRow: number) {
    return canvasCol + canvasRow * this.numCanvasRows;
  }

  /**
   * Prime the cache for all coordinates - useful to prevent lag from
   * intensive calculations at first full render.
   */
  protected primeCache() {
    for (let c = 0; c < this.numCanvasCols; c++) {
      for (let r = 0; r < this.numCanvasCols; r++) {
        this.cachedForCoordinates(c, r);
      }
    }
  }

  /**
   * A caching layer is useful since some coordinate calcs can be costly, and
   * it's likely the same coordinate will be access multiple times (also allows
   * for priming at instantiation.
   */
  private forCoordinateCache = new Map<string, T>();
  private cachedForCoordinates(canvasCol: number, canvasRow: number) {
    const key = `${canvasCol}x${canvasRow}`;
    const cachedVal = this.forCoordinateCache.get(key);
    if (cachedVal) {
      return cachedVal;
    }
    const val = this.forCoordinates(canvasCol, canvasRow);
    this.forCoordinateCache.set(key, val);
    return val;
  }
}
