import React from 'react';
import styled from 'styled-components';
import { CvData, data } from './data';

const CVStyles = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');

  font-family: Lato, sans-serif;
  margin: 0 auto;
  max-width: 600px;
  padding: 24px;

  header {
    display: flex;
    justify-content: space-between;

    .contact {
      font-size: 0.8rem;
      line-height: 1.3rem;
      text-align: right;
    }
  }

  header,
  section {
    padding-bottom: 24px;
  }

  section {
    page-break-before: auto;
    page-break-inside: avoid;
  }

  h1 {
    font-size: 2.25rem;
    font-weight: 800;
    letter-spacing: 2.5px;
    margin-bottom: 4px;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 12px;
  }

  h3,
  .employment .summary,
  .education ul {
    color: #777;
    font-size: 0.7rem;
    font-weight: bold;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .occupation {
    color: #777;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 5px;
    margin-bottom: 4px;
    text-transform: uppercase;
  }

  .key {
    float: right;
    font-size: 0.7rem;
    font-style: italic;
    margin-top: -1.4rem;
    vertical-align: baseline;
  }

  .experienceGroup {
    margin-bottom: 8px;

    &:last-child {
      /* collapse the last li's margin */
      margin-bottom: -4px;
    }

    .amount-h {
      font-weight: 400;
    }
    .amount-m {
      font-weight: 300;
    }
    .amount-l {
      font-weight: 100;
    }

    ul {
      align-items: baseline;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;

      li {
        flex-shrink: 0;
        margin-right: 8px;
        margin-bottom: 4px;
      }
    }
  }

  @media print, (min-width: 600px) {
    .employment .summary,
    .education li {
      display: flex;
      position: relative;

      > div {
        flex-grow: 0;
        flex-shrink: 0;
        text-align: center;
        white-space: nowrap;
        width: 60%;

        &:first-child {
          text-align: left;
          width: 20%;
        }
        &:last-child {
          text-align: right;
          width: 20%;
        }
      }
    }
  }

  @media screen and (max-width: 599px) {
    .employment .summary,
    .education li {
      line-height: 1.5;
    }
  }

  .employment li {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .summary {
      margin-bottom: 4px;
    }

    .description {
      font-size: 0.9rem;
      line-height: 1.25;
      text-align: justify;
    }
  }

  .education li {
    margin-bottom: 4px;
  }

  @media only print {
    height: auto;
    margin: 0;
    max-width: unset;
    overflow: visible;
    padding: 0;
    width: auto;

    @page {
      margin: 0 1cm;
      size: portrait;
    }
  }
`;

const UnconnectedCV: React.FC<CvData> = ({
  name,
  occupation,
  phone,
  email,
  location,
  experienceGroups,
  employment,
  education
}) => (
  <CVStyles>
    <header>
      <div className="who">
        <h1 className="name">{name}</h1>
        <div className="occupation">{occupation}</div>
      </div>

      <div className="contact">
        <div className="phone">{phone}</div>
        <div className="email">{email}</div>
        <div className="location">{location}</div>
      </div>
    </header>

    <main>
      <section className="experience">
        <h2>Experience</h2>
        <i className="key">Weight reflects amount of experience</i>
        {experienceGroups.map((sg) => (
          <div className="experienceGroup" key={sg.name}>
            <h3>{sg.name}</h3>
            <ul>
              {sg.items
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((s) => (
                  <li key={s.name} className={`amount-${s.experience}`}>
                    {s.name}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </section>

      <section className="employment">
        <h2>Employment</h2>
        <ul>
          {employment.map((e) => (
            <li key={e.employer + e.from}>
              <div className="summary">
                <div className="employer">{e.employer}</div>
                <div className="role">{e.role}</div>
                <div className="time">
                  {e.from} - {e.to}
                </div>
              </div>
              <div className="description">
                <div>{e.description}</div>
              </div>
            </li>
          ))}
        </ul>
      </section>

      <section className="education">
        <h2>Education</h2>
        <ul>
          {education.map((e) => (
            <li key={e.institution + e.from}>
              <div className="institution">{e.institution}</div>
              <div className="degree">{e.degree}</div>
              <div className="time">
                {e.from} - {e.to}
              </div>
            </li>
          ))}
        </ul>
      </section>
    </main>
  </CVStyles>
);

export const CV: React.FC<{}> = () => <UnconnectedCV {...data} />;
