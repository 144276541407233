import { AbstractPattern } from './AbstractPattern';
import { Pattern } from './Pattern';

export type RepeatingPatternOptions<T> = { sequence2d: T[][] };
export class RepeatingPattern<T> extends AbstractPattern<T>
  implements Pattern<T> {
  constructor(
    numCanvasCols: number,
    numCanvasRows: number,
    private opts: RepeatingPatternOptions<T>
  ) {
    super(numCanvasCols, numCanvasRows);
  }

  forCoordinates(canvasCol: number, canvasRow: number): T {
    const row = this.opts.sequence2d[canvasRow % this.opts.sequence2d.length];
    return row[canvasCol % row.length];
  }
}
