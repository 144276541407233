import { AbstractPattern } from './AbstractPattern';
import { Pattern } from './Pattern';

export type WrappingPatternOptions<T> = { sequence1d: T[] };
export class WrappingPattern<T> extends AbstractPattern<T>
  implements Pattern<T> {
  constructor(
    numCanvasCols: number,
    numCanvasRows: number,
    private opts: WrappingPatternOptions<T>
  ) {
    super(numCanvasCols, numCanvasRows);
  }

  forCoordinates(canvasCol: number, canvasRow: number): T {
    const i = this.toIndex(canvasCol, canvasRow);
    return this.opts.sequence1d[i % this.opts.sequence1d.length];
  }
}
