import { Pattern as PatternInterface } from './Pattern';
import { RepeatingPattern, RepeatingPatternOptions } from './RepeatingPattern';
import { ShutterPattern, ShutterPatternOptions } from './ShutterPattern';
import { SolidPattern, SolidPatternOptions } from './SolidPattern';
import { WrappingPattern, WrappingPatternOptions } from './WrappingPattern';

export type Pattern<T> = PatternInterface<T>;

export type CurriedPattern<T> = (
  numCanvasCols: number,
  numCanvasRows: number
) => Pattern<T>;

type PatternOptions<T> =
  | ({ type: 'repeating'; sequence2d: T[][] } & Partial<
      RepeatingPatternOptions<T>
    >)
  | ({ type: 'solid'; sequence2d: T[][]; defaultValue: T } & Partial<
      SolidPatternOptions<T>
    >)
  | ({ type: 'wrap'; sequence1d: T[] } & Partial<WrappingPatternOptions<T>>)
  | ({ type: 'shutter'; sequence1d: T[] } & Partial<ShutterPatternOptions<T>>);

/**
 * Output includes transformed value (e.g. color to NodeProperties)
 */
export const createTransformedPattern = <PreTransform, PostTransform>(
  numCanvasCols: number,
  numCanvasRows: number,
  options: PatternOptions<PreTransform>,
  {
    transform
  }: {
    transform: (pre: PreTransform) => PostTransform;
  }
): Pattern<PostTransform> => {
  switch (options.type) {
    case 'repeating':
      return new RepeatingPattern<PostTransform>(numCanvasCols, numCanvasRows, {
        ...options,
        sequence2d: options.sequence2d.map((r) => r.map(transform))
      });
    case 'solid':
      return new SolidPattern<PostTransform>(numCanvasCols, numCanvasRows, {
        alignRows: 'top',
        alignColumns: 'left',
        columnOffset: 0,
        rowOffset: 0,
        ...options,
        defaultValue: transform(options.defaultValue),
        sequence2d: options.sequence2d.map((r) => r.map(transform))
      });
    case 'wrap':
      return new WrappingPattern<PostTransform>(numCanvasCols, numCanvasRows, {
        ...options,
        sequence1d: options.sequence1d.map(transform)
      });
    case 'shutter':
      return new ShutterPattern<PostTransform>(numCanvasCols, numCanvasRows, {
        ...options,
        sequence1d: options.sequence1d.map(transform)
      });
  }
};

export const createCurriedColorPattern = <T>(
  options: PatternOptions<string>,
  defaults: Partial<T> = {}
): CurriedPattern<{ color: string }> =>
  createCurriedTransformedPattern(options, {
    transform: (v) => ({ ...defaults, color: v })
  });

export const createCurriedTransformedPattern = <PreTransform, PostTransform>(
  patternOptions: PatternOptions<PreTransform>,
  opts: { transform: (p: PreTransform) => PostTransform }
): CurriedPattern<PostTransform> => (
  numCanvasCols: number,
  numCanvasRows: number
) =>
  createTransformedPattern(numCanvasCols, numCanvasRows, patternOptions, opts);

export const createCurriedPattern = <T>(
  options: PatternOptions<T>
): CurriedPattern<T> =>
  createCurriedTransformedPattern(options, {
    transform: (v) => v
  });
