import React, { useEffect, useMemo, useState } from 'react';
import 'reset-css';
import styled from 'styled-components';
import './app.scss';
import { HeaderProps, useHeader } from './components/Header';
import { BLACK, CARAFE, defaultState, RPC } from './lib';
import { Menu } from './components/Menu';
import { Narrator } from './components/Narrator';
import { Showtime, ShowtimeState } from './components/Showtime';

const ShowtimeContainer = styled.div`
  background-color: ${CARAFE};
  bottom: 0;
  display: flex;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s;
  z-index: 1;

  &.shrunk {
    position: absolute;

    @media (orientation: landscape) {
      transform: scale(0.5) translate(-48px);
      transform-origin: right;
    }

    @media (orientation: portrait) {
      transform: scale(0.5) translateY(-48px);
      transform-origin: bottom;
    }
  }
`;

const Overlay = styled.div`
  bottom: -8px;
  border: solid 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  cursor: pointer;
  left: -8px;
  position: absolute;
  transition: all 0.2s;
  right: -8px;
  top: -8px;

  &:hover {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);
  }

  &:active {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const MainPage: React.FC<{ menuDisplayedAtOpen?: boolean }> = ({
  menuDisplayedAtOpen = false
}) => {
  const [showtimeState, setShowtimeState] = useState<ShowtimeState>(
    defaultState
  );
  const [menuDisplayed, setMenuDisplayed] = useState(menuDisplayedAtOpen);

  useEffect(() => {
    const toggleState = (e: KeyboardEvent) => {
      console.log(e.key);
      if (e.key !== 'Escape') {
        return;
      }
      setMenuDisplayed(!menuDisplayed);
    };

    document.addEventListener('keyup', toggleState);
    return () => {
      document.removeEventListener('keyup', toggleState);
    };
  }, [menuDisplayed, setMenuDisplayed]);

  useEffect(() => {
    switch (showtimeState.rpc) {
      case RPC.HANDLE_MAIN_SEQUENCE_ENDED:
        setMenuDisplayed(true);
        break;
      default:
      // no-op
    }
  }, [showtimeState.rpc]);

  const headerProps = useMemo(
    () =>
      ({
        iconForm: menuDisplayed ? 'x' : 'hamburger',
        color: menuDisplayed ? BLACK : 'rgba(128, 128, 128, 0.5)',
        onClick: () => setMenuDisplayed(!menuDisplayed)
      } as HeaderProps),
    [menuDisplayed, setMenuDisplayed]
  );
  useHeader(headerProps);

  return (
    <>
      <ShowtimeContainer className={menuDisplayed ? 'shrunk' : ''}>
        {menuDisplayed && <Overlay onClick={() => setMenuDisplayed(false)} />}
        <Showtime onStateChange={setShowtimeState} sequence="master" />
        <Narrator script={showtimeState.narration} />
      </ShowtimeContainer>
      <Menu />
    </>
  );
};
