import { Context, NodeProperties } from '../model';

export const transferNodeProperties = (
  from: Partial<NodeProperties>,
  to: Partial<NodeProperties>,
  propertiesToTransfer = [
    'size',
    'color',
    'cx',
    'cy',
    'transitionTimeMs',
    'delayTimeMs'
  ] as Array<string & keyof NodeProperties>
) => {
  for (const property of propertiesToTransfer) {
    if (property in from) {
      (to as any)[property] = from[property];
    }
  }
};

export type NodePropertiesWithRelativeSize = NodeProperties & {
  relativeSize: number;
};
export const transferNodePropertiesWithRelativeSize = (
  from: Partial<NodePropertiesWithRelativeSize>,
  to: Partial<NodeProperties>,
  context: Context,
  propertiesToTransfer?: Array<string & keyof NodeProperties>
) => {
  transferNodeProperties(from, to, propertiesToTransfer);
  if ('relativeSize' in from) {
    to.size = from.relativeSize! * context.standardNodeSize;
  }
};
