import { Context, Transition } from '../model';
import { debug } from 'debug';
import { CurriedPattern } from '../pattern';
import {
  NodePropertiesWithRelativeSize,
  transferNodePropertiesWithRelativeSize
} from '../util/transferNodeProperties';

const log = debug('viz:sequence2d:linearProgress');

export const linearProgress = (
  cursorColor: string,
  curriedPattern: CurriedPattern<Partial<NodePropertiesWithRelativeSize>>,
  speedMs = 400
): Transition =>
  function*(context: Context) {
    const pattern = curriedPattern(context.totalCols, context.totalRows);
    context.easing = 'linear';
    for (let i = 0; i < context.nodes.length; i++) {
      log('advance');
      const isUltimate = i === context.nodes.length - 1;
      const isPenultimate = i === context.nodes.length - 2;

      if (!isUltimate) {
        log('Setting next node to be cursor.');
        context.nodes[i + 1].cx =
          context.nodes[i + 1].cy === context.nodes[i].cy
            ? context.nodes[i].originalCx
            : 0;
        context.nodes[i + 1].color = cursorColor;
        context.nodes[i].color = cursorColor;
        context.transitionTimeMs = 0;
        yield;
      }

      log('Moving to next node.');
      if (!isUltimate) {
        context.nodes[i + 1].cx = context.nodes[i + 1].originalCx;
      }
      if (!isPenultimate && !isUltimate) {
        context.nodes[i + 2].color = context.backgroundColor;
      }
      transferNodePropertiesWithRelativeSize(
        pattern.forPosition(i),
        context.nodes[i],
        context
      );
      context.transitionTimeMs = speedMs;
      yield;
    }
  };
