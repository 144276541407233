import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { CV } from './components/CV';
import { HeaderProps, useHeader } from './components/Header';
import { BLACK } from './lib';

const Container = styled.div`
  padding: 60px 0 40px 0;

  @media print {
    height: auto;
    overflow: auto;
    padding-top: 1cm;
  }
`;

export const CVPage = () => {
  const history = useHistory();
  const headerProps = useMemo(
    () =>
      ({
        backgroundColor: 'rgba(128, 128, 128, 0.1)',
        underlineColor: 'rgba(0, 0, 0, 0.1)',
        iconForm: 'back',
        color: BLACK,
        onClick: () => history.push('/'),
        title: 'CV'
      } as HeaderProps),
    [history]
  );
  useHeader(headerProps);
  return (
    <>
      <Container>
        <CV />
      </Container>
    </>
  );
};
